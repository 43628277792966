import React from 'react'
import './App.css'
import { useState } from 'react'
import Logo from './assets/headerLogo.svg'

import { useLottie } from "lottie-react"
import comingSoonAnimation from "./assets/comingSoon.json"
import { Helmet } from 'react-helmet'


function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const options = {
    animationData: comingSoonAnimation,
    loop: true
  };

  const { View } = useLottie(options);

  return (
    <>
	<Helmet>
		  <title>
			Coming Soon | Sesh Hub
		  </title>
	  </Helmet><div style={AppBackground}>
			  <img src={Logo} alt="Logo" width={350} />
			  <div style={animationContainer}>
				  {View}
			  </div>
		  </div>
		  </>
  );
}

const AppBackground = {
  backgroundColor : '#222',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', 
  width: '100vw',
  height: '100vh',
  flexDirection: 'column'
}

const animationContainer = {
  width: '30%', // Set the animation's width to your desired size
  height: '30%', // Set the animation's height to your desired size
}

export default App;
